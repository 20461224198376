import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'x-box',
  styleUrl: 'x-box.sass',
})
export class XBox {
  @Prop() variant: 'section-header-normal' | 'section-header-large' | 'auth-container';

  render() {
    return (
      <Host>
        <slot></slot>
      </Host>
    );
  }
}
