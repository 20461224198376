x-box
  display: block
  box-sizing: border-box

  &[variant="section-header-normal"]
    padding: 17px 24px
  &[variant="auth-container"]
    width: 376px
    margin: 15.5vh auto 0

  &[bg="light-grey"]
    background-color: var(--surface-light-grey)

  &[scrollable]
    overflow: auto
  &[scrollable="y"]
    overflow-x: hidden
    overflow-y: auto
  &[scrollable="x"]
    overflow-x: auto
    overflow-y: hidden

  @each $key, $value in $gaps
    &[p="#{$key}"]
      padding: #{$value}px
    &[px="#{$key}"]
      padding-right: #{$value}px
      padding-left: #{$value}px
    &[py="#{$key}"]
      padding-top: #{$value}px
      padding-bottom: #{$value}px
    &[pt="#{$key}"]
      padding-top: #{$value}px
    &[pr="#{$key}"]
      padding-right: #{$value}px
    &[pb="#{$key}"]
      padding-bottom: #{$value}px
    &[pl="#{$key}"]
      padding-left: #{$value}px

    &[m="#{$key}"]
      margin: #{$value}px
    &[mx="#{$key}"]
      margin-right: #{$value}px
      margin-left: #{$value}px
    &[my="#{$key}"]
      margin-top: #{$value}px
      margin-bottom: #{$value}px
    &[mt="#{$key}"]
      margin-top: #{$value}px
    &[mr="#{$key}"]
      margin-right: #{$value}px
    &[mb="#{$key}"]
      margin-bottom: #{$value}px
    &[ml="#{$key}"]
      margin-left: #{$value}px

    &[m="-#{$key}"]
      margin: #{$value}px
    &[mx="-#{$key}"]
      margin-right: -#{$value}px
      margin-left: -#{$value}px
    &[my="-#{$key}"]
      margin-top: -#{$value}px
      margin-bottom: -#{$value}px
    &[mt="-#{$key}"]
      margin-top: -#{$value}px
    &[mr="-#{$key}"]
      margin-right: -#{$value}px
    &[mb="-#{$key}"]
      margin-bottom: -#{$value}px
    &[ml="-#{$key}"]
      margin-left: -#{$value}px
